<template>
  <main>
    <ButtonGroup shape="circle">
      <Button icon="md-return-left" @click="$router.back()">返回</Button>
      <Button icon="md-refresh" @click="loadData">刷新</Button>
    </ButtonGroup>

    <Divider />
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider />
    <pagination ref="pagination" :total="total" @on-change="loadData" />
  </main>
</template>

<script>
export default {
  data() {
    return {
      sort: '',
      total: 0,
      data: [],
      columns: [
        {
          title: '数额',
          minWidth: 120,
          key: 'amount',
          align: "center",
          sortable: 'custom',
          render: (h, params) => {
            if (params.row.amount >= 0)
              return h('span', { class: 'text-green' }, '+' + params.row.amount+'元')
            else
              return h('span', { class: 'text-red' }, params.row.amount+'元')
          }
        },
        {
          title: '事件',
          minWidth: 300,
          key: 'event',
          sortable: 'custom'
        },
        {
          title: "时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
      ]
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      let params = {
        user_id: this.$route.query.user_id,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      }

      this.$http.get('/userMoneyRecords/list', { params: params })
        .then(res => {
          this.total = res.total
          this.data = res.data
        })
    },
    changeSort(item) {
      this.sort = item.order == 'normal' ? '' : item.key + ',' + item.order
      this.$refs.pagination.page = 1
      this.loadData()
    }
  }
}
</script>